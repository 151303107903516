import * as React from "react";
import { Link } from "gatsby";

import Navigation from "./navigation";

import { useSpring, animated } from 'react-spring'

const Layout = ({ location, title, hideFooter, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (!isRootPath) {
    header = (
      <div>
        <Link className="header-link-home" to="/">
          pattra.io
        </Link>
        <Navigation />
      </div>
    )
  }

  const footer = hideFooter ? null : (
    <footer>
      © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.com">Gatsby</a>
    </footer>
  )

  const bodyAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 100,
  });

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <animated.div style={bodyAnimation}>
        <main>{children}</main>
        {footer}
      </animated.div>
    </div>
  )
}

export default Layout
