 import * as React from "react"
 import { Link } from "gatsby";
 
 const Navigation = () => {
   return (
      <ul className="navigation-container">
        <li><Link to={'/portfolio'}>Art</Link></li>
        <li><Link to={'/blog'}>Blog</Link></li>
        <li><a href="https://github.com/pattra" target="_blank" rel="noopener noreferrer">GitHub</a></li>
        <li><Link to={'/about'}>Bio</Link></li>
      </ul>
   );
 }
 
 export default Navigation
 